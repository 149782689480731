body {
	/*font: 18px "Century Gothic", Futura, sans-serif;*/
	font-size: 18px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	/*padding: 0px;		*/
	margin: 0px;
}

.HomeRecipeListItem-container {
	margin-top: 12px;

	width: 640px;
	height: 480px;
}
.HomeRecipeListItem-picture {
	background-size: cover;
	background-position: center;

	/* remove underline on link */
	text-decoration: none;

	/* center text */
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	display: flex;

	width: auto;
	height: 480px;
}

.HomeRecipeList-heading {
	/* match recipelistitem.css recipe-list-item */
	font-weight: bold;
	text-align: left;
  
	width: 640px;
	max-width: 100%;
}

.HomeRecipeList-container {
	margin-top: 12px;
	margin-bottom: 12px;
	margin-left: 8px;

	display: flex;
	align-items: center;
	flex-direction: column;
}


@media screen and (max-width:640px) {
	.HomeRecipeList-container {
		margin-right: 8px;
	}
}