.recipe-list-item {
  list-style-type: none;
  margin-top: 12px;

  width: 640px;
  max-width: 100%;
}

.recipe-list-item-img {
  object-fit: cover; /* maintains ratio rather than using min/max heights */
  height:480px;
  width: 100%;
}

.recipe-list-item-container_modelandimage {
  position: relative;
}

.recipe-list-item-container_model {
  position: absolute;
  bottom: 0;
  right:0;
  border-radius: 50% 0 0;
  width: 128px;
  height: 128px;
  background-color: white;
}

@media screen and (max-width:640px) {
  .recipe-list-item-img {
    height: 336px;
  }

  /* 128 * .7 */
  .recipe-list-item-container_model {
    width: 90px;
    height: 90px;
  }
}
